import type { HTMLAttributes } from 'react'

type ListItemSeparator = HTMLAttributes<HTMLLIElement>

export default function ListItemSeparator({ ...rest }) {
	return (
		<li
			className="hds-dropdown-list-item hds-dropdown-list-item--variant-separator"
			aria-hidden="true"
			role="separator"
			{...rest}
		></li>
	)
}

ListItemSeparator.displayName = 'dd.Separator'

import type { HTMLAttributes } from 'react'

interface ListItemTitleProps extends HTMLAttributes<HTMLLIElement> {
	text: string
}

export default function ListItemTitle({ text, ...rest }: ListItemTitleProps) {
	return (
		<li
			className="hds-dropdown-list-item hds-dropdown-list-item--variant-title hds-typography-body-100 hds-font-weight-semibold"
			{...rest}
		>
			{text}
		</li>
	)
}

ListItemTitle.displayName = 'dd.Title'

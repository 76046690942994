import Image from 'next/image'
import classNames from 'classnames'
import Chevron from './chevron'
import FlightIcon from '../../flight-icon'
import { Toggle as MenuPrimitiveToggle } from '../../menu-primitive'
import { useMenuPrimitive } from '../../menu-primitive/use-menu-primitive'
import type { ButtonHTMLAttributes } from 'react'

export interface IconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	/**
	 * Value of aria-label for the ToggleIcon.
	 */
	text: string
	icon?: string
	imageSrc?: string
	hasChevron?: boolean
	size?: 'small' | 'medium'
}

export default function Icon({
	text,
	icon,
	imageSrc,
	hasChevron = true,
	size = 'medium',
	...rest
}: IconProps) {
	const { isOpen, onClickToggle } = useMenuPrimitive()

	if (!icon && !imageSrc) {
		return <></>
	}

	const iconSize = size === 'small' ? 16 : 24

	return (
		<MenuPrimitiveToggle>
			<button
				className={classNames(
					'hds-dropdown-toggle-icon',
					`hds-dropdown-toggle-icon--size-${size}`,
					{
						['hds-dropdown-toggle-icon--is-open']: isOpen,
					}
				)}
				aria-label={text}
				aria-expanded={isOpen}
				type="button"
				onClick={onClickToggle}
				{...rest}
			>
				<div className="hds-dropdown-toggle-icon__wrapper">
					{imageSrc && (
						<Image
							src={imageSrc}
							alt=""
							role="presentation"
							height={32}
							width={32}
						/>
					)}
					{icon && !imageSrc && <FlightIcon name={icon} size={iconSize} />}
				</div>
				{hasChevron && <Chevron />}
			</button>
		</MenuPrimitiveToggle>
	)
}

Icon.displayName = 'dd.ToggleIcon'

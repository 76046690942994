import classNames from 'classnames'
import type { HTMLAttributes } from 'react'

export interface BadgeCountProps extends HTMLAttributes<HTMLDivElement> {
	size?: 'small' | 'medium' | 'large'
	type?: 'filled' | 'inverted' | 'outlined'
	color?: 'neutral' | 'neutral-dark-mode'
	/**
	 * Text value that renders in the Badge Count.
	 */
	text: string
}

function BadgeCount({
	text,
	size = 'medium',
	type = 'filled',
	color = 'neutral',
	className,
	...rest
}: BadgeCountProps) {
	return (
		<div
			className={classNames(
				'hds-badge-count',
				`hds-badge-count--size-${size}`,
				`hds-badge-count--type-${type}`,
				`hds-badge-count--color-${color}`,
				className
			)}
			{...rest}
		>
			{text}
		</div>
	)
}

export default BadgeCount

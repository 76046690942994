export const hashicorpDestinations: string[] = [
	'app.terraform.io',
	'boundaryproject.io',
	'cloud.hashicorp.com',
	'consul.io',
	'developer.hashicorp.com',
	'hashicorp.com',
	'nomadproject.io',
	'packer.io',
	'portal.cloud.hashicorp.com',
	'registry.terraform.io',
	'terraform.io',
	'vagrantup.com',
	'vaultproject.io',
	'waypointproject.io',
] as const

const containsHashiCorpDestination = (str: string): boolean =>
	hashicorpDestinations.some(function (destination) {
		return str.indexOf(destination) >= 0
	})

/**
 * This function determines if a `url` is a HashiCorp link (relative URLs, hashes,
 * or a url with a HashiCorp domain) or a  non-HashiCorp link.
 *
 * @param {string | undefined} url - The URL to be parsed. It may be a relative URL, an anchor, or a full external/internal URL.
 *
 * @returns {{ linkType: 'inbound' | 'outbound', href: string }}
 *   An object with two properties:
 *     - `linkType`: Indicates whether the URL is 'inbound' or 'outbound'. Can be used to conditionally
 * 		     render the value of the target attribute.
 *     - `href`: The parsed href (may include pathname, search, and hash for internal URLs).
 *
 * @throws {Error} Throws an error if the URL is undefined or invalid.
 */

// @TODO: delete forceExternal when io sites are shutdown

export function parseUrl(
	url: string | undefined,
	forceExternal?: boolean
): {
	linkType: 'inbound' | 'outbound' | ''
	href: string
} {
	if (!url) {
		console.log('No url to parse, returning empty string')
		return { linkType: '', href: '' }
	}

	if (isRelative(url) || isAnchor(url)) {
		return { linkType: 'inbound', href: url }
	}

	if (!forceExternal && isInternal(url)) {
		try {
			const { pathname, search, hash } = new URL(url)
			const href = pathname + search + hash
			return { linkType: 'inbound', href }
		} catch (error) {
			console.log(`Invalid href: ${url}`)
			return { linkType: '', href: '' }
		}
	}

	return { linkType: 'outbound', href: url }
}

const isRelative = (url: string) => url.startsWith('/')
const isAnchor = (url: string) => url.startsWith('#')

/**
 * Determines whether the provided url uses hashicorp.com or www.hashicorp.com as the hostname.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the link uses a HashiCorp domain, otherwise false.
 */

export const isInternal = (url: string): boolean => {
	const hostname = new URL(url).hostname
	return hostname === 'hashicorp.com' || hostname === 'www.hashicorp.com'
}

/**
 * Determines whether the provided url uses a HashiCorp hostname. This is used in several
 * components to open a new tab for non-HashiCorp links.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the link uses a HashiCorp domain, otherwise false.
 */

export const hasHashiCorpDestination = (url: string): boolean => {
	// Fast return in case a relative URL or anchor is passed in
	if (isRelative(url) || isAnchor(url)) return true
	try {
		const hostname = new URL(url).hostname
		const isHashiCorpDestination = containsHashiCorpDestination(hostname)
		return isHashiCorpDestination
	} catch (e) {
		console.error('Error: `url` is not a valid URL', e)
		return false
	}
}

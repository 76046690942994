import classNames from 'classnames'
import * as MenuPrimitive from '../menu-primitive'
import * as Toggle from './toggle'
import * as ListItem from './list-item'
import type { HTMLAttributes, ReactNode } from 'react'

export interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
	/**
	 * If true, container is given full width styles. (Added by web presence team)
	 */
	isFullWidth?: boolean
	/**
	 * If isInline prop is provided then the element will be displayed as inline-block (useful to achieve specific layouts like in a container with right alignment). Otherwise it will be have a block layout.
	 */
	isInline?: boolean
	/**
	 * Callback function invoked when the Dropdown is closed, if provided.
	 */
	onClose?: () => void
}

function Root({
	isFullWidth,
	isInline,
	onClose,
	children,
	...rest
}: DropdownProps) {
	return (
		<MenuPrimitive.Provider
			className={classNames('hds-dropdown', {
				['hds-dropdown--is-inline']: isInline,
				['hds-dropdown--width-full']: isFullWidth,
			})}
			onClose={onClose}
			isFullWidth={isFullWidth}
			{...rest}
		>
			{children}
		</MenuPrimitive.Provider>
	)
}

Root.displayName = 'Dropdown'

export interface ContentProps {
	listPosition?:
		| 'right'
		| 'left'
		| 'bottom-left'
		| 'bottom-right'
		| 'top-left'
		| 'top-right'
	width?: `${number}px` | `${number}em` | `${number}%`
	/**
	 * If a height prop is provided then the list will have a fixed height.
	 */
	height?: `${number}px` | `${number}em`
	children: ReactNode
}

function Content({
	listPosition = 'bottom-right',
	width,
	height,
	children,
}: ContentProps) {
	return (
		<MenuPrimitive.Content>
			<div
				className={classNames(
					'hds-dropdown__content',
					`hds-dropdown__content--position-${listPosition}`,
					{ ['hds-dropdown__content--fixed-width']: !!width }
				)}
				style={{ width, height }}
			>
				{children}
			</div>
		</MenuPrimitive.Content>
	)
}

Content.displayName = 'dd.Content'

function List({ children }: { children: ReactNode }) {
	return <ul className="hds-dropdown__list">{children}</ul>
}

List.displayName = 'dd.List'

export interface HeaderFooterProps extends HTMLAttributes<HTMLDivElement> {
	hasDivider?: boolean
}

function Header({ hasDivider, children, ...rest }: HeaderFooterProps) {
	return (
		<div
			className={classNames('hds-dropdown__header', {
				['hds-dropdown__header--with-divider']: hasDivider,
			})}
			{...rest}
		>
			{children}
		</div>
	)
}

Header.displayName = 'dd.Header'

function Footer({ hasDivider, children, ...rest }: HeaderFooterProps) {
	return (
		<div
			className={classNames('hds-dropdown__footer', {
				['hds-dropdown__footer--with-divider']: hasDivider,
			})}
			{...rest}
		>
			{children}
		</div>
	)
}

Footer.displayName = 'dd.Footer'

export { Root, Toggle, Content, List, Header, Footer, ListItem }

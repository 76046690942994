'use client'
import { m } from 'framer-motion'
import Image from 'next/image'
import s from './style.module.css'

const WIDTH = 1216
const HEIGHT = 445
const RAINBOW_PATHS = [
	'M29.4862 458.288C106.093 325.601 167.028 220.059 250.879 74.8249C270.529 40.7908 306.864 19.7884 346.163 19.7884L879.146 19.7884C918.445 19.7884 954.759 40.7543 974.409 74.7884L1195.53 457.788',
	'M1170.74 458.788L961.625 96.5894C941.975 62.5553 905.661 41.5894 866.362 41.5894L358.379 41.5894C319.08 41.5894 282.748 62.5861 263.098 96.6202C184.029 233.572 126.076 333.95 54.0005 458.788',
	'M79.0951 459.789L276.201 118.391C295.851 84.3569 332.165 63.3911 371.464 63.3911L854.447 63.3912C893.746 63.3912 930.069 84.3729 949.719 118.407C1024.12 247.27 1079.12 342.536 1146.82 459.789',
	'M1119.98 458.288L936.323 140.192C916.674 106.158 880.36 85.1922 841.061 85.1922L383.078 85.1921C343.778 85.1921 307.464 106.158 287.815 140.192L103.873 458.788',
	'M129.148 457.788L300.502 160.993C320.152 126.959 356.466 105.993 395.765 105.993L828.748 105.993C868.047 105.993 904.361 126.959 924.011 160.993L1095.94 458.788',
	'M154.097 458.288L313.153 182.794C332.803 148.76 369.117 127.794 408.416 127.794L816.399 127.795C855.698 127.795 892.012 148.76 911.662 182.795L1071.01 458.788',
]

const HeroAnimation = () => {
	return (
		<div className={s.root}>
			<Rainbow />
			<div className={s.image}>
				<Image
					width={WIDTH}
					height={HEIGHT}
					src="https://www.datocms-assets.com/2885/1713557376-hero_final_final_fiiiiinal.svg"
					alt="Image of HashiCorp Cloud Platform dashboard"
					priority
				/>
			</div>
		</div>
	)
}

const Rainbow = () => {
	const pathDuration = 1.5
	const gradientDuration = 2.5
	const degreeStart = 180
	const degreeEnd = 360
	return (
		<svg
			className={s.rainbow}
			viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clipPath)">
				<mask
					id="mask"
					width="100%"
					height="100%"
					x="0"
					y="0"
					maskUnits="userSpaceOnUse"
					style={{ maskType: 'luminance' }}
				>
					<path fill="#fff" d="M1216 0H0v445h1216V0Z" />
				</mask>
				<g mask="url(#mask)">
					{RAINBOW_PATHS.map((path, index) => {
						return (
							<m.path
								d={path}
								key={index}
								stroke={`url(#pathGradient)`}
								strokeWidth={3}
								initial={{ pathLength: 0 }}
								animate={{ pathLength: 1 }}
								transition={{
									duration: pathDuration,
									delay: index * 0.1,
								}}
							/>
						)
					})}
				</g>
			</g>
			<defs>
				<m.linearGradient
					id="pathGradient"
					gradientUnits="userSpaceOnUse"
					initial={{
						gradientTransform: `rotate(${degreeStart})`,
					}}
					animate={{
						gradientTransform: `rotate(${degreeEnd})`,
					}}
					transition={{
						duration: gradientDuration,
					}}
				>
					<stop stopColor="#F097E1" stopOpacity="0.24" />
					<stop offset="0.122024" stopColor="#F097E1" />
					<stop offset="0.203749" stopColor="#C042DB" />
					<stop offset="0.325" stopColor="#8670EA" />
					<stop offset="0.46" stopColor="#5ABCD1" />
					<stop offset="0.585" stopColor="#E6EDEF" />
					<stop offset="0.67" stopColor="#EADC82" />
					<stop offset="0.77" stopColor="#FF7579" />
					<stop offset="0.86" stopColor="#D27A7E" />
					<stop offset="1" stopColor="#D27A7E" stopOpacity="0" />
				</m.linearGradient>
				<clipPath id="clipPath">
					<path fill="#fff" d="M0 0h1216v445H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default HeroAnimation

import classNames from 'classnames'
import FlightIcon from '../../flight-icon'
import Interactive, { type InteractiveProps } from '../../interactive'

export interface ListItemInteractiveProps
	extends Omit<InteractiveProps, 'children'> {
	/**
	 * Text to be used in the item. If no text value is defined, an error will be thrown.
	 */
	text: string
	/**
	 * Color applied to the text and (optional) icons.
	 */
	color?: 'action' | 'critical'
	/**
	 * Leading icon. Acceptable value: any [icon](https://helios.hashicorp.design/icons/library) name.
	 */
	icon?: string
	/**
	 * Trailing icon. Acceptable value: any [icon](https://helios.hashicorp.design/icons/library) name.
	 */
	trailingIcon?: string
	/**
	 * Controls if the item is in “loading” state. When in this state, the item is not actually interactive, but you can pass the other expected arguments for the item (they’re simply ignored).
	 */
	isLoading?: boolean
}

export default function ListItemInteractive({
	text,
	color = 'action',
	icon,
	isLoading,
	trailingIcon,
	...rest
}: ListItemInteractiveProps) {
	return (
		<li
			className={classNames(
				'hds-dropdown-list-item',
				'hds-dropdown-list-item--variant-interactive',
				`hds-dropdown-list-item--color-${color}`
			)}
		>
			{isLoading ? (
				<div className="hds-dropdown-list-item__interactive-loading-wrapper">
					<div className="hds-dropdown-list-item__interactive-icon hds-dropdown-list-item__interactive-icon--leading">
						<FlightIcon name="loading" isInlineBlock={false} />
					</div>
					<div className="hds-dropdown-list-item__interactive-text hds-typography-body-100 hds-font-weight-regular">
						{text}
					</div>
				</div>
			) : (
				<Interactive {...rest}>
					{icon && (
						<span className="hds-dropdown-list-item__interactive-icon hds-dropdown-list-item__interactive-icon--leading">
							<FlightIcon name={icon} isInlineBlock={false} />
						</span>
					)}
					<div className="hds-dropdown-list-item__interactive-text hds-typography-body-200 hds-font-weight-medium">
						<span>{text}</span>
					</div>
					{trailingIcon && (
						<span className="hds-dropdown-list-item__interactive-icon hds-dropdown-list-item__interactive-icon--trailing">
							<FlightIcon name={trailingIcon} isInlineBlock={false} />
						</span>
					)}
				</Interactive>
			)}
		</li>
	)
}

ListItemInteractive.displayName = 'dd.Interactive'

import FlightIcon from '../../flight-icon'

export default function Chevron() {
	return (
		<div className="hds-dropdown-toggle-chevron">
			<FlightIcon
				name="chevron-down"
				className="flight-icon flight-icon-chevron-down"
				isInlineBlock={false}
			/>
		</div>
	)
}

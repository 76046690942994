import classNames from 'classnames'
import Badge from '../../badge'
import BadgeCount from '../../badge-count'
import { Toggle as MenuPrimitiveToggle } from '../../menu-primitive'
import { useMenuPrimitive } from '../../menu-primitive/use-menu-primitive'
import FlightIcon from '../../flight-icon'
import Chevron from './chevron'
import type { ButtonHTMLAttributes, ReactNode } from 'react'

export interface ToggleButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	/**
	 * Text of the ToggleButton. If no text value is defined, an error will be thrown.
	 */
	text: string
	color?: 'primary' | 'secondary'
	size?: 'small' | 'medium'
	icon?: string
	isFullWidth?: boolean
	/**
	 * Displays an optional count indicator using the Badge Count component.
	 */
	count?: string
	/**
	 * Displays an optional badge indicator using the Badge component.
	 */
	badge?: string
	/**
	 * Appends an icon to the optional badge indicator.
	 */
	badgeIcon?: ReactNode
}

export default function ToggleButton({
	text,
	color = 'primary',
	size = 'medium',
	icon,
	isFullWidth,
	count,
	badge,
	badgeIcon,
	...rest
}: ToggleButtonProps) {
	const { isOpen, onClickToggle } = useMenuPrimitive()
	const badgeType = color !== 'primary' ? 'inverted' : 'filled'

	return (
		<MenuPrimitiveToggle>
			<button
				aria-expanded={isOpen}
				type="button"
				className={classNames(
					'hds-dropdown-toggle-button',
					`hds-dropdown-toggle-button--size-${size}`,
					`hds-dropdown-toggle-button--color-${color}`,
					{
						['hds-dropdown-toggle-button--width-full']: isFullWidth,
						['hds-dropdown-toggle-button--is-open']: isOpen,
					}
				)}
				onClick={onClickToggle}
				{...rest}
			>
				{icon && (
					<div className="hds-dropdown-toggle-button__icon">
						<FlightIcon name={icon} stretched />
					</div>
				)}
				<div className="hds-dropdown-toggle-button__text">{text}</div>
				{count && (
					<BadgeCount
						text={count}
						size="small"
						type={badgeType}
						className="hds-dropdown-toggle-button__count"
					/>
				)}
				{badge && (
					<Badge
						text={badge}
						icon={badgeIcon}
						size="small"
						type={badgeType}
						className="hds-dropdown-toggle-button__badge"
					/>
				)}
				<Chevron />
			</button>
		</MenuPrimitiveToggle>
	)
}

ToggleButton.displayName = 'dd.ToggleButton'
